import React, {useEffect, useState, useContext} from "react";
import Navbar from "./Navbar";
import axios from "axios";
import {useCookies} from 'react-cookie';
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = ({isAuthenticated, children}) => {
    const [cookies] = useCookies(['firstName', 'lastName', 'navbarOrganisation', 'authKey']);


    if (isAuthenticated) {
        let userDetails;
        if (cookies.firstName) {
            const keys = ['firstName', 'lastName', 'organization'];
            const result = [cookies.firstName, cookies.lastName, cookies.navbarOrganisation];
            userDetails = Object.fromEntries(keys.map((key, index) => [key, result[index]]));
        }
        if (cookies.authKey === 2) {
            return (
                <>
                    <Navbar userDetails={userDetails}/>
                    <div className="mt-3">
                        <div className="flex gap-4 w-full">
                            <div className="MainContent">
                                {children}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </>
            );
        } else if (cookies.authKey === 1) {
            return (
                <>
                    <Navbar userDetails={userDetails}/>
                    <div className="mt-3">
                        <div className="flex gap-4 w-full">
                            <div className="secondCard">
                                <Sidebar/>
                            </div>
                            <div className="MainContent">
                                {children}
                            </div>
                        </div>
                    </div>
                </>
            )
        }

    } else {
        return (
            <>
                <div className="flex flex-auto">
                    <div className="grow">
                        <div>{children}</div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
};

export default Layout;
