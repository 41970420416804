import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from "../components/CommonComponents";

const QuestionSetsDashboard = () => {
    const navigate = useNavigate();

    return (
        <div className="p-8">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-bold mb-2">View Question Sets</h2>
                    <p className="text-gray-600 mb-4">Browse through all available question sets.</p>
                    <Button
                        // className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => navigate('/view-question-sets')}
                    >
                        View
                    </Button>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-bold mb-2">Edit Question Sets</h2>
                    <p className="text-gray-600 mb-4">Modify existing question sets.</p>
                    <Button
                        // className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => navigate('/edit-questions-sets')}
                    >
                        Edit
                    </Button>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-bold mb-2">Add Question Sets</h2>
                    <p className="text-gray-600 mb-4">Create new question sets.</p>
                    <Button
                        // className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => navigate('/add-question-sets')}
                    >
                        Edit
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default QuestionSetsDashboard;
