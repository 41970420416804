import "./App.css";
import React, {useState, useEffect} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/Login";
import SignUp from "./components/signUp";
import OTPVerification from "./components/otpVerification";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import {useCookies} from 'react-cookie';
import Welcome from "./pages/Welcome";
import Questionnaire from "./pages/Questionnaire";
import AdminAnalytics from "./pages/AdminAnalytics";
import ViewQuestionSets from "./pages/ViewQuestionSets";
import EditQuestionSets from "./pages/EditQuestionSets";
import ViewPatientDetails from "./pages/ViewPatientDetails";
import AddQuestionSets from "./pages/AddQuestionSets";
import QuestionSetsDashboard from "./pages/QuestionSetsDashboard";
import PatientsDashboard from "./pages/PatientsDashboard";

const App = () => {
    const [cookies] = useCookies(['firstName', 'lastName', 'navbarOrganisation', 'authKey']);
    const firstName = cookies['firstName'];
    const authKey = cookies['authKey'];

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Function to set authentication status
    const setAuthenticationStatus = (status) => {
        setIsAuthenticated(status);
    };

    // Check if the user is authenticated
    useEffect(() => {
        if (firstName) {
            setAuthenticationStatus(true);
        } else {
            setAuthenticationStatus(false);
        }
    }, [firstName, cookies]);

    return (
        <Layout isAuthenticated={isAuthenticated}>
            <Routes>
                {!isAuthenticated ? (
                    <>
                        <Route path="/login" element={<Login setAuthenticationStatus={setAuthenticationStatus} />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/otp-verification" element={<OTPVerification />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                    </>
                ) : null}

                {isAuthenticated ? (
                    <>
                        {authKey === 2 ?
                            <Route path="/" element={<Welcome/>}/>
                            : <Route path="/" element={<AdminAnalytics/>}/>
                        }
                        {/*<Route path="/" element={<Welcome/>}/>*/}
                        <Route path="/questionnaire" element={<Questionnaire/>}/>
                        {/*<Route path="/analytics" element={<AdminAnalytics/>}/>*/}
                        <Route path="/question-sets-dashboard" element={<QuestionSetsDashboard/>}/>
                        <Route path="/patients-dashboard" element={<PatientsDashboard/>}/>
                        <Route path="/view-question-sets" element={<ViewQuestionSets/>}/>
                        <Route path="/add-question-sets" element={<AddQuestionSets/>}/>
                        <Route path="/edit-questions-sets" element={<EditQuestionSets/>}/>
                        <Route path="/view-patient-details" element={<ViewPatientDetails/>}/>
                    </>
                ) : null}

                {/*{isAuthenticated && authKey === 2 ? <Route path="/*" element={<Navigate to="/" />} /> : null}*/}
                {isAuthenticated ? <Route path="/*" element={<Navigate to="/" />} /> : null}
                {/*{isAuthenticated && authKey === 1 ? <Route path="/*" element={<Navigate to="/analytics" />} /> : null}*/}
                {!isAuthenticated ? <Route path="/*" element={<Navigate to="/login" />} /> : null}
            </Routes>
        </Layout>
    )
};

export default App;
