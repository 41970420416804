import React, {useState, useEffect, useRef, useMemo} from 'react';
// import {questionsList} from '../common/questions';
import linda from '../assets/linda.png';
import Linda from "../assets/linda.png";
import {Button} from "../components/CommonComponents";
import frontImage from "../assets/front.png"
import backImage from "../assets/back.png"
import frontCoordinates from "../common/front_mapping.json"
import backCoordinates from "../common/back_mapping.json"
import {
    deviation,
    findClosestOption,
    pushDeviationsToDb,
    pushQuizResponsesToDb,
    getQuestionSet,
    textToSpeech
} from "../api/serverAPI"
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {medbotVoices} from "../components/Mapping";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.interimResults = false;
recognition.lang = 'en-US';

const sessionId = Math.floor(100000 + Math.random() * 900000)

const euclideanDistance = (point1, point2) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

const findClosestFrontBodyPart = (touchPoint) => {
    let closestPart = null;
    let minDistance = Infinity;
    for (const part in frontCoordinates) {
        const [xPart, yPart] = frontCoordinates[part];
        const distance = euclideanDistance(touchPoint, [xPart, yPart]);
        if (distance < minDistance) {
            minDistance = distance;
            closestPart = part;
        }
    }
    return closestPart;
};

const findClosestBackBodyPart = (touchPoint) => {
    let closestPart = null;
    let minDistance = Infinity;
    for (const part in backCoordinates) {
        const [xPart, yPart] = backCoordinates[part];
        const distance = euclideanDistance(touchPoint, [xPart, yPart]);
        if (distance < minDistance) {
            minDistance = distance;
            closestPart = part;
        }
    }
    return closestPart;
};

const Questionnaire = () => {
    const audioRef = useRef(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [responses, setResponses] = useState([]);
    const [deviations, setDeviations] = useState([]);
    const [pause, setPause] = useState(false);
    const [skipToEnd, setSkipToEnd] = useState(false);
    const [showListeningMic, setShowListeningMic] = useState(false);
    const [pauseButtonText, setPauseButtonText] = useState('Pause');
    const [speechText, setSpeechText] = useState('');
    const [showFirstImage, setShowFirstImage] = useState(true);
    const [sections, setSections] = useState(frontCoordinates);
    const [botResponse, setBotResponse] = useState(null);
    const [bodyPart, setBodyPart] = useState('');
    const navigate = useNavigate();
    const recognitionRef = useRef(null);
    const pauseRef = useRef(pause);
    const skipToEndRef = useRef(skipToEnd);
    const [cookies] = useCookies(['voice', 'email']);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [questionsList, setQuestionsList] = useState([]);

    useEffect(() => {
        getQuestionSet({
            email: cookies.email,
        }).then(r => {
            console.log(r.data)
            setQuestionsList(r.data);
        });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Reset the selected option when the question index changes
        setSelectedOption('');

        if (questionsList.length > 0 && questionsList[currentQuestionIndex]?.question) {
            speak(questionsList[currentQuestionIndex].question);
        }
    }, [questionsList, currentQuestionIndex]);


    useEffect(() => {
        recognitionRef.current = recognition;
    }, []);

    useEffect(() => {
        pauseRef.current = pause;
    }, [pause]);

    useEffect(() => {
        skipToEndRef.current = skipToEnd;
    }, [skipToEnd]);

    useEffect(() => {
        recognition.onresult = (event) => {
            const speechResult = event.results[0][0].transcript.toLowerCase();
            setSpeechText(speechResult);
            setBotResponse(null);
            setShowListeningMic(false);
            handleSpeechOptionChange(speechResult);
        };

        return () => {
            recognitionRef.current.abort();
            setShowListeningMic(false);
        };
    }, [pause, currentQuestionIndex, selectedOption, questionsList]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // padStart ensures the month and day are two digits, padding with a zero if necessary.
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const handleFlip = () => {
        setShowFirstImage(!showFirstImage);
        if (showFirstImage) {
            setSections(frontCoordinates);
        } else {
            setSections(backCoordinates);
        }
    };

    const handleImageClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        let mappedBodyPart = "";

        if (showFirstImage) {
            mappedBodyPart = findClosestFrontBodyPart([x, y]);
            setBodyPart(mappedBodyPart);
            handleOptionChange(mappedBodyPart);
        } else {
            mappedBodyPart = findClosestBackBodyPart([x, y]);
            setBodyPart(mappedBodyPart);
            handleOptionChange(mappedBodyPart);
        }
    };

    const handlePause = () => {
        let buttonText = pause ? "Pause" : "Resume";
        setPauseButtonText(buttonText);
        setPause(!pause);

        if (!pause) {
            setShowListeningMic(false);
            setBotResponse("Your session is paused. Click on resume to resume the session.");
            recognition.stop();  // Stop recognition immediately when pausing
            speak("Your session is paused. Click on resume to resume the session.");
        } else {
            setBotResponse(null);
            let resumeText = "Let's go back to your daily health check... "
            resumeText = resumeText.concat(questionsList[currentQuestionIndex].question);
            speak(resumeText);  // Restart the session with the new question
        }
    };

    const handleSkipToEnd = (textToSpeak) => {
        setSkipToEnd(true);
        setCurrentQuestionIndex(questionsList.length);
        recognitionRef.current.abort();
        setShowListeningMic(false);
        speak(textToSpeak);
        pushQuizResponsesToDb({
            session_id: sessionId,
            patient_id: cookies.email,
            responses: responses,
            date: formatDate(currentDateTime),
            timestamp: formatTime(currentDateTime)
        }).then(r => console.log(r.data))
        pushDeviationsToDb({
            session_id: sessionId,
            patient_id: cookies.email,
            deviations: deviations,
            date: formatDate(currentDateTime),
            timestamp: formatTime(currentDateTime)
        }).then(r => console.log(r.data))
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);

        recognitionRef.current.abort();  // Stop recognition when an option is selected
        setShowListeningMic(false);

        const newResponse = {
            question: questionsList[currentQuestionIndex].question,
            answer: option
        };
        setResponses((prevResponses) => [...prevResponses, newResponse]);
        moveToNextQuestion();
    };

    const handleNoPain = () => {
        handleOptionChange("No pain.");
    };

    const handleSpeechOptionChange = (text) => {
        const threshold = 0.50
        // console.log(currentQuestionIndex)
        // console.log(questionsList[currentQuestionIndex])
        const currentOptions = questionsList[currentQuestionIndex]?.options.map(option => option.toLowerCase());

        if (currentOptions) {
            findClosestOption({
                options: currentOptions,
                text: text
            }).then((response) => {
                const highestSimilarity = response.data['match'];
                if (highestSimilarity > threshold) {
                    const matchedOption = response.data['option'];
                    handleOptionChange(matchedOption);
                } else if (highestSimilarity <= threshold) {
                    deviation({
                        text: text
                    }).then((response) => {
                        console.log(response)
                        setBotResponse(response.data['class']);
                        const newDeviation = {
                            question: questionsList[currentQuestionIndex].question,
                            userResponse: text,
                            botResponse: response.data['class']
                        };
                        setDeviations((prevDeviations) => [...prevDeviations, newDeviation]);
                        if (response.data['class'] === "Patient wants to take a break") {
                            handlePause()
                        } else if (response.data['class'] === "I have sent an alert to the emergency response team. They will be arriving shortly. In the meanwhile, please check if there is someone near you who can assist you. Also, lets stop this conversation for now and continue this later. You can restart the app in case you need anything else. ") {
                            setSpeechText(null);
                            setShowListeningMic(false);
                            speak(response.data['class'])
                            setTimeout(() => {
                                setBotResponse(response.data['class']);
                            }, 1500);
                            handleSkipToEnd(response.data['class'])
                        } else {
                            // Repeat the current question
                            recognitionRef.current.abort();  // Stop recognition to avoid multiple instances
                            setShowListeningMic(false);

                            let askAgain = response.data['class']
                            askAgain = askAgain.concat("Let's go back to your daily health check. ".concat(questionsList[currentQuestionIndex].question))
                            speak(askAgain);
                        }
                    })
                }
            })
        }
    };

    const moveToNextQuestion = () => {
        recognitionRef.current.abort();  // Stop recognition to avoid multiple instances
        setShowListeningMic(false);

        setTimeout(() => {
            if (currentQuestionIndex < questionsList.length - 1) {
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                setSpeechText(null);
                setShowListeningMic(false);
            } else {
                setSpeechText(null);
                setCurrentQuestionIndex(questionsList.length);
                recognitionRef.current.abort();
                setShowListeningMic(false);
                speak("Thank you for your responses! Hope to connect with you again soon.");
                pushQuizResponsesToDb({
                    session_id: sessionId,
                    patient_id: cookies.email,
                    responses: responses,
                    date: formatDate(currentDateTime),
                    timestamp: formatTime(currentDateTime)
                }).then(r => console.log(r.data))
                pushDeviationsToDb({
                    session_id: sessionId,
                    patient_id: cookies.email,
                    deviations: deviations,
                    date: formatDate(currentDateTime),
                    timestamp: formatTime(currentDateTime)
                }).then(r => console.log(r.data))
            }
            if (!pause) {
                try {
                    setBotResponse(null);
                    // recognition.start();  // Start recognition again after moving to the next question
                } catch (error) {
                    console.error("Failed to start speech recognition:", error);
                }
            }
            setBotResponse(null);

        }, 1500);
    };

    const handleBack = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
            setResponses((prevResponses) => prevResponses.slice(0, -1));
        }
    };

    const handleHome = () => {
        navigate("/");
    }

    const speak = async (text) => {
        const audio = audioRef.current;
        let voice;
        if (cookies.voice) {
            voice = cookies.voice
        } else {
            voice = medbotVoices[0].value
        }
        const body = JSON.stringify({voice: voice, text: text});
        try {
            const response = await fetch('https://genai.krtrimaiq.ai:8001/text_to_speech', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });
            console.log(response)

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);

            mediaSource.addEventListener('sourceopen', async () => {
                const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

                const reader = response.body.getReader();

                const pump = async () => {
                    try {
                        const {done, value} = await reader.read();
                        if (done) {
                            mediaSource.endOfStream();
                            audio.play();
                            audio.onended = () => {
                                if (!skipToEndRef.current && !pauseRef.current) {
                                    if (currentQuestionIndex+1 < questionsList.length) {
                                        if (recognitionRef.current && recognitionRef.current.state !== 'started') {
                                            setShowListeningMic(true);
                                            recognitionRef.current.start();
                                        }
                                    }
                                }
                            };
                            return;
                        }
                        sourceBuffer.addEventListener('updateend', () => {
                            if (!sourceBuffer.updating) {
                                pump();
                            }
                        });
                        sourceBuffer.appendBuffer(value);
                    } catch (error) {
                        console.error('Error reading stream:', error);
                    }
                };

                await pump();
            });

            audio.oncanplaythrough = () => {
                audio.play(); // Ensure audio is loaded before playing
            };
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    }

    const currentQuestion = questionsList[currentQuestionIndex];

    return (
        <div className="flex flex-col justify-center items-center mt-16 z-40 drop-shadow-xl mb-20">
            <div className="w-1/2 flex justify-between mb-4">

                <div className="flex">
                    {!pause &&
                        <Button onClick={handleHome}>Home</Button>
                    }
                </div>
                {!skipToEnd && !(currentQuestionIndex === questionsList.length) &&
                    <div className="flex space-x-2">
                        {!pause &&
                            <>
                                <Button onClick={handleBack} disabled={currentQuestionIndex === 0}>Back</Button>
                                <Button onClick={() => handleSkipToEnd("Thank you for your responses! Hope to connect with you again soon.")} className="ml-2">Skip to the end</Button>
                            </>
                            }
                        <Button onClick={handlePause} className="ml-2">{pauseButtonText}</Button>
                    </div>
                }

            </div>
            {!pause &&
                <div className="bg-white mt-3 rounded-lg p-6 shadow-lg w-1/2">
                    {questionsList.length > 0 ? (
                        currentQuestionIndex < questionsList.length ? (
                            <div style={{marginBottom: '20px'}}>
                                <div className="flex justify-start mb-4">
                                    <img src={linda} alt="Linda" className="w-16 h-20 mr-4 rounded-full"/>
                                    <div className="subheading text-xl mt-3">{currentQuestion.question}</div>
                                </div>
                                {currentQuestion.image &&
                                    <div>
                                        <div className="flex space-x-2">
                                            <Button onClick={handleFlip}>Flip</Button>
                                            <Button onClick={handleNoPain}>I have no pain</Button>
                                        </div>
                                        <img
                                            src={showFirstImage ? frontImage : backImage}
                                            alt="body parts"
                                            onClick={handleImageClick}
                                            style={{width: '500px', height: 'auto', cursor: 'pointer'}}
                                        />
                                        {bodyPart &&
                                            <div className="mt-3 rounded-lg p-6 w-1/2">{bodyPart}</div>
                                        }
                                    </div>
                                }
                                {currentQuestion.options?.map((option, idx) => (
                                    <div key={idx} className="mb-2 ml-15">
                                        <input
                                            type="radio"
                                            id={`option-${idx}`}
                                            name="option"
                                            value={option}
                                            checked={selectedOption === option}
                                            onChange={() => handleOptionChange(option)}
                                            className="mr-2"
                                        />
                                        <label htmlFor={`option-${idx}`} className="text-lg">{option}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            skipToEnd || responses.length === questionsList.length ? (
                                <div>
                                    <div className="flex justify-center items-center">
                                        <img className="image" src={Linda} alt="Linda"/>
                                        <p className="message">Thank you for your responses! Hope to connect with you again soon.</p>
                                    </div>
                                    <div className="responses flex flex-col ml-17 mt-7">
                                        {responses.map((response, index) => (
                                            <div key={index}>
                                                <strong>Q{index + 1}. {response.question}</strong>
                                                <div>A: {response.answer}</div>
                                                <br/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                        )
                    ) : (
                        <p>Loading questions...</p>
                    )}
                </div>
            }

            {showListeningMic ?
                <div className="flex flex-row justify-start w-1/2 mt-10">
                    <div>....</div>
                </div>
                : null
            }
            {speechText && !botResponse &&
                <div className="bg-white mt-3 rounded-lg p-6 shadow-lg w-1/2">{speechText}</div>
            }
            {botResponse ?
                <div className="bg-white mt-3 rounded-lg p-6 shadow-lg w-1/2">{botResponse}</div>
                : null
            }
            <audio ref={audioRef}/>
        </div>
    );
};

export default Questionnaire;
