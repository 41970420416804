import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/CommonComponents";
import {toast} from "react-hot-toast";

const EditQuestionSets = () => {
    const [questionSets, setQuestionSets] = useState([]);
    const [selectedSetId, setSelectedSetId] = useState('');
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [originalQuestions, setOriginalQuestions] = useState([]);
    const [editedQuestions, setEditedQuestions] = useState([]);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const navigate = useNavigate();

    console.log(isSubmitVisible);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://genai.krtrimaiq.ai:8001/admindisplayquestions/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setQuestionSets(data);
                    // Reset selections
                    setSelectedSetId('');
                    setSelectedQuestions([]);
                    setOriginalQuestions([]);
                    setEditedQuestions([]);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Disable browser back button navigation
        const disableBackNavigation = () => {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        disableBackNavigation();

        return () => {
            // Clean up function to restore default behavior
            window.onpopstate = null;
        };
    }, []);

    const handleGoBack = () => {
        navigate('/success');
    };

    const handleChange = (e) => {
        const selectedId = e.target.value;
        setSelectedSetId(selectedId);

        const selectedSet = questionSets.find(set => set.set_id.toString() === selectedId);
        console.log(selectedSet);
        if (selectedSet) {
            const questions = selectedSet.set;
            setSelectedQuestions(questions);
            setOriginalQuestions([...questions]); // Save the original questions
            setEditedQuestions([...questions]);
        } else {
            setSelectedQuestions([]);
            setOriginalQuestions([]);
            setEditedQuestions([]);
        }
    };

    const hasEmptyFields = () => {
        const hasEmpty = editedQuestions.some(question =>
            !question.question || question.options.length === 0 ||
            question.options.some(option => !option)
        );

        console.log('Edited Questions:', editedQuestions);
        console.log('Has Empty Fields:', hasEmpty);

        return hasEmpty;
    };

    const handleEditQuestion = (qIndex, event) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions[qIndex].question = event.target.value;
        setEditedQuestions(updatedQuestions);
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleEditOption = (qIndex, oIndex, event) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions[qIndex].options[oIndex] = event.target.value;
        setEditedQuestions(updatedQuestions);
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleDeleteOption = (qIndex, oIndex) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions[qIndex].options.splice(oIndex, 1);
        setEditedQuestions(updatedQuestions);
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleDeleteQuestion = (qIndex) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions.splice(qIndex, 1);
        setEditedQuestions(updatedQuestions);
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleUndoChanges = () => {
        console.log('Undoing changes...');
        setEditedQuestions([...originalQuestions]);
        setIsSubmitVisible(false);
    };

    const handleAddQuestion = (index = -1) => {
        const newQuestion = {
            question: '',
            options: [''],
            image: false,
        };
        const updatedQuestions = index === -1
            ? [newQuestion, ...editedQuestions]
            : [
                ...editedQuestions.slice(0, index + 1),
                newQuestion,
                ...editedQuestions.slice(index + 1)
            ];
        setEditedQuestions(updatedQuestions);
        setSelectedQuestions(updatedQuestions); // Ensure the UI updates to reflect the new question
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleAddOption = (qIndex) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions[qIndex].options.push('');
        setEditedQuestions(updatedQuestions);
        setIsSubmitVisible(!hasEmptyFields());
    };

    const handleSubmitQuestions = async () => {
        if (hasEmptyFields()) {
            toast.error('Please fill in all fields before submitting.');
            return;
        }

        try {
            console.log(selectedSetId);
            console.log(editedQuestions);
            const response = await fetch('https://genai.krtrimaiq.ai:8001/admineditquestions/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ question_set_id: selectedSetId, question_set: editedQuestions })
            });

            if (response.ok) {
                toast.success('Questions updated successfully!');
                // window.location.reload();
                // navigate('/edit-questions-sets');
                setIsSubmitVisible(false);
                setSelectedSetId('');
                setSelectedQuestions([]);
                setOriginalQuestions([]);
                setEditedQuestions([]);

                fetch('https://genai.krtrimaiq.ai:8001/admindisplayquestions/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(data => setQuestionSets(data))
                    .catch(error => console.error('Error fetching data:', error));

            } else {
                toast.error('Failed to update questions.');
            }
        } catch (error) {
            console.error('Error updating questions:', error);
            toast.error('Error updating questions. Please try again.');
        }
    };

    const handleDeleteQuestionSet = async () => {
        if (window.confirm('Are you sure you want to delete this question set?')) {
            try {
                const response = await fetch('https://genai.krtrimaiq.ai:8001/admindeletequestionset/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ question_set_id: selectedSetId })
                });

                if (response.ok) {
                    toast.success('Question set deleted and IDs updated successfully!');
                    setSelectedSetId('');
                    setSelectedQuestions([]);
                    setOriginalQuestions([]);
                    setEditedQuestions([]);
                    setIsSubmitVisible(false);
                    // Refetch the question sets to get the updated list
                    fetch('https://genai.krtrimaiq.ai:8001/admindisplayquestions/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                        .then(response => response.json())
                        .then(data => setQuestionSets(data))
                        .catch(error => console.error('Error fetching data:', error));
                } else {
                    toast.error('Failed to delete question set.');
                }
            } catch (error) {
                console.error('Error deleting question set:', error);
                toast.error('Error deleting question set. Please try again.');
            }
        }
    };

    const renderOptions = (options, qIndex) => {
        if (!Array.isArray(options) || options.length === 0) {
            return (
                <>
                    <button
                        onClick={() => handleAddOption(qIndex, 0)}
                        className="p-1 bg-blue text-white rounded mb-3 mr-2"
                    >
                        Add option
                    </button>
                    <br />
                </>
            )

        } else {
            return (
                <ul className="list-none mb-2">
                    {options.map((option, oIndex) => (
                        <li key={oIndex} className="flex items-center mb-2">
                            <input
                                type="text"
                                value={option}
                                onChange={(e) => handleEditOption(qIndex, oIndex, e)}
                                className="w-full p-2 mb-2 text-lg border rounded-md"
                                placeholder="Enter option"
                            />
                            <button
                                onClick={() => handleAddOption(qIndex, oIndex)}
                                className="p-2"
                            >
                                <i className="fa-solid fa-add fa-xl"></i>
                            </button>
                            <button
                                onClick={() => handleDeleteOption(qIndex, oIndex)}
                                className="p-2"
                            >
                                <i className="fa-solid fa-trash-can fa-xl"></i>
                            </button>
                        </li>
                    ))}
                </ul>
            );
        }
    };

    return (
        <div className="flex flex-col justify-start h-screen w-full p-5 overflow-auto">
            <div className="subheader text-center mt-2">
                Edit Question Sets
            </div>
            <div className="flex flex-row space-x-2 p-5">
                <div className="subheading text-center mt-2">
                    Select Question Set
                </div>
                <select onChange={handleChange} value={selectedSetId} className="p-2 text-lg border rounded-md">
                    <option value="">Select</option>
                    {questionSets.map((questionSet, index) => (
                        <option key={index} value={questionSet.set_id}>
                            {questionSet.set_id}
                        </option>
                    ))}
                </select>
            </div>

            {selectedSetId && (
                <div className="flex flex-row p-5 space-x-2">
                    <Button onClick={handleDeleteQuestionSet}>Delete Question Set</Button>
                    {editedQuestions.length === 0 && (
                        <Button onClick={() => handleAddQuestion(-1)}>Add Question</Button>
                    )}
                </div>
            )}

            {editedQuestions.length > 0 && (
                <div className="w-full max-w-8xl p-5 ">
                    <div className="flex flex-row justify-between">
                        <h3 className="text-2xl font-bold mb-5">Set ID: {selectedSetId}</h3>
                    </div>

                    <ul className="list-none p-0">
                        {editedQuestions.map((question, qIndex) => (
                            <div key={qIndex} className="mb-5 bg-white shadow-2xl p-5">
                                <p className="mb-2 font-semibold">
                                    <strong>Question {qIndex + 1}</strong>
                                </p>
                                <input
                                    type="text"
                                    value={question.question}
                                    onChange={(e) => handleEditQuestion(qIndex, e)}
                                    className="w-full p-2 mb-2 text-lg border rounded-md"
                                    placeholder="Enter question"
                                />
                                <p className="mb-2 font-semibold">
                                    <strong>Options:</strong>
                                </p>
                                {renderOptions(question.options, qIndex)}
                                <hr className="my-4" />

                                <button
                                    onClick={() => handleAddQuestion(qIndex)}
                                    className="p-2 text-sm text-white bg-blue rounded-md mr-5"
                                >
                                    Add Question Below
                                </button>
                                <button
                                    onClick={() => handleDeleteQuestion(qIndex)}
                                    className="p-2 text-sm text-white bg-red-500 rounded-md"
                                >
                                    Delete Question
                                </button>
                            </div>
                        ))}
                    </ul>
                </div>
            )}

            {isSubmitVisible && (
                <div className="flex flex-row justify-start p-5">
                    <Button onClick={handleSubmitQuestions}>
                        Submit Changes
                    </Button>
                </div>
            )}
        </div>
    );
};

export default EditQuestionSets;
