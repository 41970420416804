import React, {useEffect, useState} from "react";
import AuthForm from "../components/authForm";
import {authFields} from '../contents/authentication';
import {useNavigate, Link} from 'react-router-dom';
import axios from "axios";
import {Toaster, toast} from "react-hot-toast";
import {login, signup} from "../api/serverAPI";

const Signup = () => {
    const router = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleSignup = async (formData) => {
        try {
            setLoading(true);
            signup(formData).then((response) => {
                if (response.data.status === "Stored in database") {
                    setTimeout(() => {
                        router("/login");
                    }, 1000);
                    toast.success("Signup successful. Please login.");
                } else {
                    toast.error(response.data.status);
                }
            });
        } catch (error) {
            console.log("Signup failed", error.message);
            toast.error("Signup failed: ", error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {/*loading ? "processing" : ""*/}
            <AuthForm
                fields={authFields.signup}
                buttonText="Signup"
                onSubmit={handleSignup}
                title="Create a new account"
                loading={loading}
            />
            <Toaster position="top-center" reverseOrder={false}/>
        </div>
    );
};

export default Signup;
