import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ViewQuestionSets = () => {
    const [questionSets, setQuestionSets] = useState([]);
    const [selectedSetId, setSelectedSetId] = useState('');
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://genai.krtrimaiq.ai:8001/admindisplayquestions/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(data => setQuestionSets(data))
            .catch(error => console.error('Error fetching data:', error));

        const disableBackNavigation = () => {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        disableBackNavigation();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const handleNavigation = (e) => {
        e.preventDefault();
        navigate('/edit-questions-sets');
    };

    const handleChange = (e) => {
        const selectedId = e.target.value;
        setSelectedSetId(selectedId);

        const selectedSet = questionSets.find(set => set.set_id === parseInt(selectedId));
        if (selectedSet) {
            setSelectedQuestions(selectedSet.set);
        } else {
            setSelectedQuestions([]);
        }
    };

    return (
        <div className="flex flex-col justify-start h-screen w-full p-5 overflow-auto">
            <div className="subheader text-center mt-2">
                View Question Sets
            </div>

            <div className="flex flex-row space-x-2 p-2">
                <div className="subheading text-center mt-2">
                    Select Question Set
                </div>
                <select onChange={handleChange} value={selectedSetId} className="p-2 text-lg border rounded-md">
                    <option value="">Select</option>
                    {questionSets.map((questionSet) => (
                        <option key={questionSet.set_id} value={questionSet.set_id}>
                            {questionSet.set_id}
                        </option>
                    ))}
                </select>
            </div>

            {selectedSetId ? (
                selectedQuestions.length > 0 ? (
                    <div className="w-full max-w-8xl bg-white p-5 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">Set ID: {selectedSetId}</h2>
                        <ul className="list-none p-0">
                            {selectedQuestions.map((question, qIndex) => (
                                <li key={qIndex} className="mb-6 p-4 border border-gray-300 rounded-lg bg-white shadow-md">
                                    <p className="text-lg font-semibold mb-2"><strong>Question:</strong> {question.question}</p>
                                    <p className="text-lg font-semibold mb-2"><strong>Options:</strong></p>
                                    <ul className="list-disc pl-5">
                                        {question.options && question.options.length > 0 ? (
                                            question.options.map((option, oIndex) => (
                                                <li key={oIndex} className="mb-1">{option}</li>
                                            ))
                                        ) : (
                                            <li>No options available</li>
                                        )}
                                    </ul>
                                    {question.image && (
                                        <img src={question.image} alt={`Question ${qIndex} image`} className="mt-4 max-w-full h-auto"/>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className="text-2xl mt-10">This question set is empty. Click <a href="#" style={{
                        color: 'blue',
                        textDecoration: 'underline'
                    }} onClick={handleNavigation}>here</a> to add questions.</div>
                )
            ) : null
            }
        </div>
    );
};

export default ViewQuestionSets;
