import React, {useState, useContext} from 'react';
import AuthForm from './authForm';
import {authFields} from '../contents/authentication';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Toaster, toast} from 'react-hot-toast';
import {CookiesProvider, useCookies} from 'react-cookie';
import {addQuestionSet, login} from "../api/serverAPI";


const Login = () => {
    const router = useNavigate();
    const [loading, setLoading] = useState(false);
    const [, setCookie, removeCookie] = useCookies(['jwtToken', 'email', 'firstName', 'lastName', 'navbarOrganisation', 'authKey']);


    const handleLogin = async (formData) => {
        try {
            setLoading(true);
            login(formData).then((response) => {
                if (response) {
                    console.log(response);
                    if (response.data.status === "success") {
                        setCookie("email", formData['email'])
                        setCookie("firstName", response.data['first_name'])
                        setCookie("lastName", response.data['last_name'])
                        setCookie("navbarOrganisation", response.data['organization'])
                        setCookie("authKey", response.data['Auth_key'])
                        toast.success("Login success");
                        router("/");
                    }
                    else {
                        toast.error(response.data.status);
                    }
                } else {
                    toast.error("Login failed");
                }
            });

        } catch (error) {
            console.log("Login failed", error.message);
            await toast.error("Login failed", error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <AuthForm fields={authFields.login} buttonText="Login" onSubmit={handleLogin} title="Login"
                      loading={loading}/>
            <Toaster position="top-center" reverseOrder={false}/>
        </div>
    );
};

export default Login;
