import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-amber-50 text-center py-5 mt-20 w-full fixed bottom-0 drop-shadow-xl">
            <div className="font-heading text-2xl font-bold">In partnership with Invocate Health</div>
        </footer>
    );
};

export default Footer;
