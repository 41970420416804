import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import enterprise from "../assets/enterprise.svg";
import knowledgeManagement from "../assets/knowledge-management.svg";
import bi from "../assets/bi.svg";
import information from "../assets/information.svg";
import roles from "../assets/roles.svg";
import content_summarize from "../assets/content_summarize.svg";
import imageGeneration from "../assets/imageGeneration.svg";
import QAGeneration from "../assets/QAGeneration.svg";
import casestudy from "../assets/casestudy.svg";
import tutorbot from "../assets/tutorbot.svg";

const Sidebar = () => {
    const [subMenu, setSubMenu] = useState(-1);
    const location = useLocation();

    const Menus = [
        {
            title: "Analytics",
            path: "/analytics",
            src: bi,
            Submenu: []
        },
        {
            title: "Question sets",
            path: "/question-sets-dashboard",
            src: QAGeneration,
            Submenu: []
        },
        {
            title: "Patients",
            path: "/patients-dashboard",
            src: roles,
            Submenu: []
        },
    ];

    const toggleSubMenu = (index) => {
        if (subMenu === index) {
            setSubMenu(-1); // Close the submenu if it's already open
        } else {
            setSubMenu(index); // Open the clicked submenu
        }
    };

    const isMenuActive = (menu) => {
        if (location.pathname === menu.path) {
            return true;
        }
        if (menu.Submenu) {
            return menu.Submenu.some((submenu) => location.pathname === submenu.path);
        }
        return false;
    };

    return (
        <>
            <div className="customScrollbar scrollbarStyle rounded-tr-lg duration-300 bg-[#23479F] mt-20">
                <ul className="p-4">
                    {Menus.map((menu, index) => (
                        <div key={index}>
                            <Link to={menu.path}>
                                <li className={`rounded-lg ${menu.gap ? "mt-9" : "mt-2"} ${isMenuActive(menu) && "colorbg"}`}>
                                    <div onClick={() => toggleSubMenu(index)} className="flex items-center gap-x-3 p-3 text-base font-normal rounded-lg cursor-pointer hover:bg-[#1D3C86] hover:text-black">
                                        <span>
                                            <img src={menu.src} alt="" className="p-0.5 max-w-fit" />
                                        </span>
                                        <button className="text-left origin-left duration-300 hover:block text-white">
                                            {menu.title}
                                        </button>
                                        {menu.Submenu.length > 0 && (
                                            <span className={`ml-auto transform transition-transform duration-300 ${subMenu === index ? "rotate-180" : "rotate-0"}`}>
                                                <span className="text-white">&#9660;</span>
                                            </span>
                                        )}
                                    </div>
                                </li>
                            </Link>
                            {subMenu === index && menu.Submenu.length > 0 && (
                                <ul>
                                    {menu.Submenu.map((submenu, subIndex) => (
                                        <div key={subIndex}>
                                            <Link to={submenu.path}>
                                                <li
                                                    className={`flex text-left gap-x-3 p-3 mx-10 text-base font-normal rounded-lg cursor-pointer hover:bg-[#1D3C86] hover:text-black ${submenu.gap ? "mt-9" : "mt-2"} ${location.pathname === submenu.path && "colorbg"}`}
                                                    //onClick={() => setSubMenu(-1)} // Close the dropdown when submenu is clicked
                                                >
                                                    <div>
                                                        <span className="origin-left duration-300 hover:block text-white">
                                                            {submenu.title}
                                                        </span>
                                                    </div>
                                                </li>
                                            </Link>
                                        </div>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
