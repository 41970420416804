const Spinner = ({size = 'md', colour = 'black'}) => {
    if (size === 'lg')
        return (
            <div className="flex justify-center my-20">
                <svg className="animate-spin h-12 w-12 mr-3" viewBox="0 0 24 24">
                    <circle
                        className="opacity-0"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke={'white'}
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-100"
                        fill={colour}
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
                Please wait while images are loading.
            </div>
        );
    else if (size === 'sm')
        return (
            <div className="flex justify-center">
                <svg className="animate-spin h-4 w-4 mr-3" viewBox="0 0 24 24">
                    <circle
                        className="opacity-0"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke={'white'}
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-100"
                        fill={colour}
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
        );

    return (
        <div className="flex justify-center">
            <svg className="animate-spin h-8 w-8 mr-3" viewBox="0 0 24 24">
                <circle
                    className="opacity-0"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke={'white'}
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-100"
                    fill={colour}
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>
    );
};

export default Spinner;
