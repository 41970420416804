import React from "react";
import Sidebar from "../components/Sidebar";

const AdminAnalytics = () => {
    return (
        <div className="flex">
            <div className="flex items-center justify-center h-screen w-full">
                <div className="text-2xl font-bold">Coming soon...</div>
            </div>
        </div>
    );
};

export default AdminAnalytics;
