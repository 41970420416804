// SettingsModal.jsx
import React, {useState} from "react";
import SimpleDropdown from "./SimpleDropdown";
import {medbotVoices} from "./Mapping";
import {Button} from "./CommonComponents";
import {useCookies} from "react-cookie";

const SettingsModal = ({ isOpen, onClose }) => {
    const [cookies, setCookie,] = useCookies(['voice']);
    let currentVoice;
    if (cookies.voice) {
        currentVoice = medbotVoices.find(v => v.value === cookies.voice);
    } else {
        currentVoice = medbotVoices[0]
    }

    if (!isOpen) return null;

    const handleSubmit = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleVoiceChange = (e) => {
        setCookie('voice', e.value);
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 backdrop-blur-sm">
            <div className="bg-white rounded-lg shadow-lg p-6 w-1/3 relative">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Settings</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        &times;
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="my-8">
                        <div className="text-lg font-medium text-indigo-800 text-left my-2">
                            Select voice
                        </div>
                        <div className="w-full">
                            <SimpleDropdown
                                defaultValue={currentVoice}
                                onSelect={handleVoiceChange}
                                options={medbotVoices}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <Button size="medium" onClick={handleSubmit}>Submit</Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SettingsModal;
