import axios from "axios";

export const deviation = (data) => {
    return axios.post("https://genai.krtrimaiq.ai:8001/deviation", data);
}

export const textToSpeech = async (data) => {
    await fetch('https://genai.krtrimaiq.ai:8001/text_to_speech', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export const findClosestOption = (data) => {
    return axios.post("https://genai.krtrimaiq.ai:8001/closest_option", data);
}

export const getFrontBodyPart = (data) => {
    return axios.post("https://genai.krtrimaiq.ai:8001/frontclosestbodypart", data);
}

export const getBackBodyPart = (data) => {
    return axios.post("https://genai.krtrimaiq.ai:8001/backclosestbodypart", data);
}

export const pushQuizResponsesToDb = (data) => {
    console.log(data)
    return axios.post("https://genai.krtrimaiq.ai:8001/pushresponses/", data);
}

export const pushDeviationsToDb = (data) => {
    console.log(data)
    return axios.post("https://genai.krtrimaiq.ai:8001/pushdeviations/", data);
}

export const getQuestionSet = (data) => {
    console.log(data)
    return axios.post("https://genai.krtrimaiq.ai:8001/displayquestions/", data);
}

export const addQuestionSet = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/adminaddquestions/', data, {headers: headers});
}

export const getNewQuestionSetNumber = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/displaylatestqset/', data, {headers: headers});
}

export const login = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/login', data, {headers: headers});
}

export const signup = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/signup/', data, {headers: headers});
}
