import React, {useEffect, useRef} from 'react';
import '../App.css';
import {Button} from "../components/CommonComponents";
import Linda from "../assets/linda.png";
import { useNavigate } from 'react-router-dom';
import {useCookies} from "react-cookie";


const WelcomePage = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['organisation']);
    const audioRef = useRef(null);

    const handleClick = () => {
        navigate('/questionnaire');
    };

    return (
        <div className="container">
            <h1 className="header">{cookies['organisation']}</h1>
            <h1 className="subheader">Patient Health Check</h1>
            <div className="content">
                <img
                    className="image"
                    src={Linda}
                    alt="Linda"
                />
                <div className="flex flex-row">
                    <p className="message">
                        Hi! Welcome to your daily check-in. I am Linda, your personal health care messenger. I am here to check on your daily health status.
                        <br/>
                        <p className="message-center">Click Start when you are ready.</p>
                    </p>
                </div>

            </div>

            <div className="mt-5">
                <Button size="medium" onClick={handleClick}>Start</Button>
            </div>
            <audio ref={audioRef}/>
        </div>
    );
};

export default WelcomePage;
