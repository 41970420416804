import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from "../components/CommonComponents";

const PatientsDashboard = () => {
    const navigate = useNavigate();

    return (
        <div className="p-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-bold mb-2">View Patient Details</h2>
                    <p className="text-gray-600 mb-4">View patients, assign or update question sets to patients.</p>
                    <Button
                        // className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => navigate('/view-patient-details')}
                    >
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PatientsDashboard;
