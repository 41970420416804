// SimpleDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
 
const SimpleDropdown = ({ options, onSelect, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
 
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
 
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };
 
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
 
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
 
  return (
    <div className="relative text-left" ref={dropdownRef}>
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            onClick={handleToggle}
            className="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            <span>{selectedOption ? selectedOption.label : 'Select an option'}</span>
            <svg
              className={`h-5 w-5 ${isOpen ? 'transform rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </span>
      </div>
 
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg "  style={{  zIndex: '10' }}>
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              {options.map((option) => (
                <div
                  key={option.value}
                  className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${
                    selectedOption && selectedOption.value === option.value
                      ? 'bg-gray-100'
                      : ''
                  }`}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
 
SimpleDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
};
 
export default SimpleDropdown;