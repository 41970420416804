import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {Button} from "../components/CommonComponents";
import {addQuestionSet, getNewQuestionSetNumber} from "../api/serverAPI";
import {toast} from "react-hot-toast";

const AddQuestionSets = () => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [questionsList, setQuestionsList] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [cookies] = useCookies(['email']);
    const email = cookies.email;
    const navigate = useNavigate();
    const [questionSetNumber, setQuestionSetNumber] = useState(null);

    const fetchQuestionSetNumber = async () => {
        try {
            getNewQuestionSetNumber().then((response) => {
                if (response) {
                    console.error(response)
                    const data = response.data;
                    setQuestionSetNumber(data.status + 1);
                } else {
                    toast.error('Failed to fetch question set number.');
                }
            });

        } catch (error) {
            console.error('Error fetching question set number:', error);
            toast.error('Error fetching question set number. Please try again.');
        }
    };

    useEffect(() => {
        fetchQuestionSetNumber();
    }, []);

    useEffect(() => {
        // Fetch current questions from backend if needed on component mount
    }, []);

    useEffect(() => {
        const disableBackNavigation = () => {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        disableBackNavigation();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleInputChange = (index, event) => {
        const newOptions = [...options];
        newOptions[index] = event.target.value;
        setOptions(newOptions);
    };

    const handleDeleteOption = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleAddQuestion = () => {
        if (question.trim() !== '' && options.some(option => option.trim() !== '')) {
            const newQuestion = {
                question: question,
                options: options.filter(option => option.trim() !== ''),
                image: false
            };
            setQuestionsList([...questionsList, newQuestion]);
            setQuestion('');
            setOptions([]);
            setIsFormValid(false);
            setIsSubmitVisible(true);
        } else {
            toast.error('Please enter a question and at least one option.');
        }
    };

    const handleDeleteQuestion = (index) => {
        const updatedQuestions = [...questionsList];
        console.log(updatedQuestions)
        updatedQuestions.splice(index, 1);
        setQuestionsList(updatedQuestions);
        if (updatedQuestions.length === 0) {
            setIsSubmitVisible(false);
        }
    };

    const handleSubmitQuestions = async () => {
        try {
            addQuestionSet({
                question_set: questionsList,
                email: email,
                questionsetid: questionSetNumber
            }).then((response) => {
                if (response) {
                    const data = response.data;
                    if (data.status === "Added question set") {
                        toast.success('Questions submitted successfully!');
                        fetchQuestionSetNumber();
                        setQuestionsList([]);
                        setIsSubmitVisible(false);
                    } else {
                        toast.error('Failed to submit questions.');
                    }
                } else {
                    toast.error('Failed to submit questions.');
                }
            });

        } catch (error) {
            console.error('Error submitting questions:', error);
            toast.error('Error submitting questions. Please try again.');
        }
    };

    useEffect(() => {
        setIsFormValid(question.trim() !== '' && options.some(option => option.trim() !== ''));
    }, [question, options]);

    return (
        <div className="p-5 flex flex-col items-center">
            <div className="subheader text-center mt-2">
                Add Question Set
            </div>
            {questionSetNumber && (
                <div className="text-xl mb-3">Question Set ID: {questionSetNumber}</div>
            )}
            <div className="w-4/5 p-5 border border-gray-300 rounded bg-white shadow-md">
                <h2 className="text-2xl mb-4">Enter Question</h2>
                <input
                    type="text"
                    placeholder="Enter your question"
                    value={question}
                    onChange={handleQuestionChange}
                    className="w-full p-2 mb-3 border border-gray-300 rounded"
                />

                {options.length === 0 ? (
                    <button
                        onClick={handleAddOption}
                        className="p-2 bg-blue text-white rounded mb-3 mr-2"
                    >
                        Add option
                    </button>
                ) : null}

                {options.map((option, index) => (
                    <div key={index} className="flex items-center mb-3">
                        <input
                            type="text"
                            placeholder={`Option ${index + 1}`}
                            value={option}
                            onChange={(e) => handleInputChange(index, e)}
                            className="w-full p-2 mr-2 border border-gray-300 rounded"
                        />
                        <button
                            onClick={handleAddOption}
                            className="p-2"
                        >
                            <i className="fa-solid fa-add fa-xl"></i>
                        </button>
                        <button
                            onClick={() => handleDeleteOption(index)}
                            className="p-2"
                        >
                            <i className="fa-solid fa-trash-can fa-xl"></i>
                        </button>
                    </div>
                ))}
                <button
                    onClick={handleAddQuestion}
                    disabled={!isFormValid}
                    className={`p-2 bg-krtrima-blue text-white rounded mt-2 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    Done
                </button>
            </div>

            {questionsList.length > 0 && (
                <div className="mt-4 w-4/5 p-5 border border-gray-300 rounded bg-white shadow-md">
                    <h3 className="text-2xl mb-4">Current Questions</h3>
                    <ul className="list-none p-0">
                        {questionsList.map((q, index) => (
                            <>
                                <div key={index} className="flex flex-row justify-between mb-3 mt-5">
                                    <div>
                                        <strong>Question:</strong> {q.question}<br/>
                                        <strong>Options:</strong> {q.options.join(', ')}
                                    </div>
                                    <button
                                        onClick={() => handleDeleteQuestion(index)}
                                        className="p-2 bg-red-500 text-white rounded mt-2"
                                    >
                                        Delete Question
                                    </button>
                                </div>
                                <hr className="my-4"/>
                            </>
                        ))}
                    </ul>
                </div>
            )}

            {isSubmitVisible && (
                <div className="mt-4 w-4/5">
                    <Button
                        onClick={handleSubmitQuestions}
                        className="p-2 bg-blue-500 text-white rounded mt-2"
                    >
                        Submit Questions
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AddQuestionSets;
