import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from "../components/CommonComponents";
import {toast} from "react-hot-toast";

const ViewPatientDetails = () => {
    const [patients, setPatients] = useState([]);
    const [questionSets, setQuestionSets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [updatingPatient, setUpdatingPatient] = useState(null);
    const [newQuestionSetId, setNewQuestionSetId] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const patientsPerPage = 10;
    const navigate = useNavigate();

    const fetchPatients = () => {
        fetch('https://genai.krtrimaiq.ai:8001/displaypatient/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => setPatients(data))
            .catch(error => console.error('Error fetching data:', error));
    };

    console.log(patients)

    const fetchQuestionSets = () => {
        fetch('https://genai.krtrimaiq.ai:8001/existingqset/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => setQuestionSets(data))
            .catch(error => console.error('Error fetching question sets:', error));
    };

    useEffect(() => {
        fetchPatients();
        fetchQuestionSets();

        const disableBackNavigation = () => {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.history.go(1);
            };
        };

        disableBackNavigation();

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const handleGoBack = () => {
        navigate('/success');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleUpdateClick = (patient) => {
        setUpdatingPatient(patient);
    };

    const handleUpdateSubmit = () => {
        fetch('https://genai.krtrimaiq.ai:8001/findandupdate/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: updatingPatient.email,
                questionset_id: newQuestionSetId
            })
        })
            .then(response => response.json())
            .then(data => {
                toast.success(data.status);
                setUpdatingPatient(null);
                setNewQuestionSetId('');
                fetchPatients();
            })
            .catch(error => console.error('Error updating data:', error));
    };

    const handleSearchSubmit = () => {
        fetch('https://genai.krtrimaiq.ai:8001/findanddisplay/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: searchEmail
            })
        })
            .then(response => response.json())
            .then(data => {
                setPatients([data]);
            })
            .catch(error => console.error('Error searching data:', error));
    };

    const indexOfLastPatient = currentPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    const currentPatients = patients.slice(indexOfFirstPatient, indexOfLastPatient);
    const totalPages = Math.ceil(patients.length / patientsPerPage);

    return (
        <div className="p-5 flex flex-col items-center h-screen overflow-auto">
            <div className="subheader text-center mt-2">
                Patient Details
            </div>
            <div className="flex mb-5 items-center space-x-2">
                <input
                    type="text"
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                    placeholder="Enter email to search"
                    className="border rounded-lg p-2"
                />
                <Button onClick={handleSearchSubmit}>Search</Button>
            </div>
            <div className="flex flex-col gap-5 w-1/2">
                {currentPatients.map((patient, index) => (
                    <Card key={index}>
                        <p><strong>Email:</strong> {patient.email}</p>
                        <p><strong>First Name:</strong> {patient.first_name}</p>
                        <p><strong>Question Set ID:</strong> {patient.questionset_id}</p>
                        {updatingPatient && updatingPatient.email === patient.email ? (
                            <div className="space-x-2">
                                <select
                                    value={newQuestionSetId}
                                    onChange={(e) => setNewQuestionSetId(e.target.value)}
                                    className="border rounded-lg p-2"
                                >
                                    <option value="">Select Question Set</option>
                                    {questionSets.map((qset) => (
                                        <option key={qset.value} value={qset.value}>
                                            {qset.label}
                                        </option>
                                    ))}
                                </select>
                                <Button size="outline" onClick={handleUpdateSubmit}>Enter</Button>
                            </div>
                        ) : (
                            <Button size="outline" onClick={() => handleUpdateClick(patient)}>Update</Button>
                        )}
                    </Card>
                ))}
            </div>
            <div className="flex justify-center mt-5">
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        className={`px-4 py-2 border rounded-lg mx-1 ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border-blue-500'}`}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ViewPatientDetails;
