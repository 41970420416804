import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {Toaster, toast} from "react-hot-toast";
import {FcInfo} from 'react-icons/fc';
import {Button} from './CommonComponents';
import Spinner from './spinner';
import Logo from "../assets/invocate.svg";

const ForgotPassword = () => {
    const router = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const notification = toast(
            'Please enter your email to verify your account.',
            {
                duration: 6000,
                icon: <FcInfo fontSize={"35px"}/>,
            }
        );

        return () => toast.dismiss(notification);
    }, []);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/generate-otp", {
                email: email,
                // application_id: 2,
            });

            if (response.data.response_code === "EMAIL_SENT") {
                // User exists, navigate to OTP verification
                // email has been sent to the user
                router("/otp-verification", {
                    state: {
                        email: email,
                        // application_id: 2,
                        pathToNavigate: "/reset-password"
                    }
                });
            } else {
                await toast.success(response.message);
            }
        } catch (error) {
            console.error('API call error:', error);
            await toast.error("User does not exists");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className='py-5'><img
                src={Logo}
                alt="Logo of krtrimaIQ Cognitive Solutions"
                className="cursor-pointer invocate-logo"
                width={350}
            /></div>
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold mb-4 text-center">Forgot Password</h2>
                <form onSubmit={handleEmailSubmit}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring focus:ring-indigo-200"
                    />

                    <div className="flex justify-center">
                        <Button size="medium" color="light-purple" type="submit">
                            {loading ? <Spinner size='sm' colour='white'/> : "Submit Email"}
                        </Button>
                    </div>
                </form>
            </div>
            <Toaster position="top-center" reverseOrder={false}/>
        </div>
    );
};

export default ForgotPassword;
